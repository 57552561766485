import Vue from 'vue';
import Router from 'vue-router';
import authStore from '@/store/modules/auth';
import Login from '@/views/Global/LoginView/index.vue';
import ContactsApp from '@/views/Contacts/index.vue';
import OrdersApp from '@/views/Orders/index.vue';
import Product from '@/views/Orders/ProductView/index.vue';
import Search from '@/views/Contacts/SearchView.vue';
import Favorite from '@/views/Contacts/FavoriteView.vue';
import ContactDetailTemplate from '@/views/Contacts/ContactDetailsView/index.vue';
import Detail from '@/views/Contacts/ContactDetailsView/DetailCard/index.vue';
import Notes from '@/views/Contacts/ContactDetailsView/NotesCard/index.vue';
import LivestockTrade from '@/views/Contacts/ContactDetailsView/LivestockTraderCard.vue';
import Sales from '@/views/Contacts/ContactDetailsView/SalesCard/index.vue';
import Products from '@/views/Contacts/ContactDetailsView/ProductsCard/index.vue';
import ContactOrdering from '@/views/Contacts/ContactDetailsView/OrderingCard/index.vue';
import OpenItems from '@/views/Contacts/ContactDetailsView/OpenItemsCard/index.vue';
import OrdersList from '@/views/Orders/OrdersList/index.vue';
import Order from '@/views/Orders/Order/index.vue';
import PlanningList from '@/views/Orders/PlanningList/index.vue';
import LicenseProblem from '@/views/Global/LicenseProblemView/index.vue';
// import Axios from 'axios';
import KACService from '@/services/KACService';
import jwtDecode from 'jwt-decode';
import AuthorizationService from '@/services/authorizationService';
import AppModulesEnums from '@/enums/AppModulesEnums';
import { IDecodedLmToken } from '@/interfaces/IDecodedLmToken';

Vue.use(Router);

// Refetch /api/userdata to get module list on each page refresh
const fetchLicenseInformation = async () => {
  // const axios = Axios;
  const kac = new KACService();
  try {
    const decodedLMToken = jwtDecode(authStore.token) as IDecodedLmToken;
    // const email = decodedToken.id;
    await kac.getLicences(decodedLMToken.customerId, process.env.VUE_APP_APP_CODE, authStore.token);

    // Return promise: fetch is done
    return new Promise(resolve => {
      resolve(true);
    });
  } catch (error) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }
};

const router = new Router({
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '*'
    },
    {
      path: '/',
      redirect: '/contacts/search'
    },
    {
      path: '/contacts',
      name: 'contacts',
      component: ContactsApp,
      children: [
        {
          path: 'favorites',
          name: 'favorites',
          component: Favorite
        },
        {
          path: 'search',
          name: 'search',
          component: Search
        },
        {
          path: 'contact_detail/:id',
          name: 'contacts/contact_detail/:id',
          component: ContactDetailTemplate,
          props: true,
          children: [
            {
              path: 'detail',
              name: 'contacts/contact_detail/:id/detail',
              component: Detail,
              props: true
            },
            {
              path: 'livestock_trade',
              name: 'contacts/contact_detail/:id/livestock_trade',
              component: LivestockTrade
            },
            {
              path: 'notes',
              name: 'contacts/contact_detail/:id/notes',
              component: Notes
            },
            {
              path: 'sales',
              name: 'contacts/contact_detail/:id/sales',
              component: Sales,
              beforeEnter: async (to, from, next) => {
                const authorizationService = new AuthorizationService();
                const isOrderAppModuleActive = await authorizationService.isModuleGranted(AppModulesEnums.ORDER);
                if (isOrderAppModuleActive) {
                  next();
                } else {
                  next('/contacts/search');
                }
              }
            },
            {
              path: 'products',
              name: 'contacts/contact_detail/:id/products',
              component: Products,
              beforeEnter: async (to, from, next) => {
                const authorizationService = new AuthorizationService();
                const isOrderAppModuleActive = await authorizationService.isModuleGranted(AppModulesEnums.ORDER);
                if (isOrderAppModuleActive) {
                  next();
                } else {
                  next('/contacts/search');
                }
              }
            },
            {
              path: 'orders',
              name: 'contacts/contact_detail/:id/orders',
              component: ContactOrdering,
              beforeEnter: async (to, from, next) => {
                const authorizationService = new AuthorizationService();
                const isOrderAppModuleActive = await authorizationService.isModuleGranted(AppModulesEnums.ORDER);
                if (isOrderAppModuleActive) {
                  next();
                } else {
                  next('/contacts/search');
                }
              }
            },
            {
              path: 'open_items',
              name: 'contacts/contact_detail/:id/open_items',
              component: OpenItems,
              beforeEnter: async (to, from, next) => {
                const authorizationService = new AuthorizationService();
                const isOrderAppModuleActive = await authorizationService.isModuleGranted(AppModulesEnums.ORDER);
                if (isOrderAppModuleActive) {
                  next();
                } else {
                  next('/contacts/search');
                }
              }
            }
          ]
        }
      ],
      meta: {
        requiresAuth: true,
        contactsRootRoute: true
      }
    },
    {
      path: '/orders',
      name: 'orders',
      component: OrdersApp,
      beforeEnter: async (to, from, next) => {
        const authorizationService = new AuthorizationService();
        const isOrderAppModuleActive = await authorizationService.isModuleGranted(AppModulesEnums.ORDER);
        if (isOrderAppModuleActive) {
          next();
        } else {
          next('/contacts/search');
        }
      },
      redirect: '/orders/all',
      children: [
        // TODO: create?product_id={productId}&contact_id={cotactId}
        {
          path: 'create',
          name: 'createOrder',
          component: Product
        },
        {
          path: 'all',
          name: 'allOrders',
          component: OrdersList
        },
        {
          path: 'planning-list',
          name: 'planningList',
          component: PlanningList
        },
        {
          path: ':orderId',
          name: 'order',
          component: Order,
          props: true
        }
      ],
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      beforeEnter: (to, from, next) => {
        if (authStore.isLoggedIn) {
          // if user is logged can't see a login page
          next('/contacts/search');
        } else {
          next();
        }
      }
    },
    {
      path: '/license-problem',
      name: 'licenseProblem',
      component: LicenseProblem,
      beforeEnter: async (to, from, next) => {
        await fetchLicenseInformation();

        if (authStore.isLoggedIn) {
          const authorizationService = new AuthorizationService();
          const isAppLicenseActive = await authorizationService.isAppLicenseActive();
          const isCoreAppModuleActive = await authorizationService.isModuleGranted(AppModulesEnums.CORE);
          if (isCoreAppModuleActive && isAppLicenseActive) {
            next('/contacts/search');
          } else {
            next();
          }
        } else {
          next('/login');
        }
      }
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  // catch page if required authorization
  const { token, email } = to.query;
  if (token && typeof token === 'string' && email && typeof email === 'string') {
    await router.app.$authService.loginByLink(email, token);
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (authStore.isLoggedIn) {
      // Fetch license info before each route action
      // TODO: consider to set this on each required route
      await fetchLicenseInformation();

      const authorizationService = new AuthorizationService();
      const isAppLicenseActive = await authorizationService.isAppLicenseActive();
      const isCoreAppModuleActive = await authorizationService.isModuleGranted(AppModulesEnums.CORE);
      if (!isCoreAppModuleActive || !isAppLicenseActive) {
        next('/license-problem');
      }

      next();
      return;
    }
    next('/login');
  } else {
    next();
  }
});

export default router;
