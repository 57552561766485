








































































































import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { ProductWithAverages } from '@/store/modules/contact/types';
import OrderDraftService from '@/services/Order/OrderDraftService';
import { IOrderDraftItem } from '@/store/modules/order/types';
import Product from '@/components/Common/ProductCard/Product.vue';
import { ItmpOrderDraftItemDetails } from '@/components/Ordering/types';
import NumberUtils from '@/utils/NumberUtils';
import UnitsEnums from '@/enums/UnitsEnums';

// Note: https://stackoverflow.com/a/64150393
// eslint-disable-next-line no-unused-vars
type validationRuleType = (data: string) => string | boolean;

export default Vue.extend({
  name: 'OrderingItem',
  components: {
    Product
  },
  props: {
    inputProduct: {
      type: Object,
      required: true
    },
    inputOrderItemCollectionIndex: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    selectedProduct: {} as ProductWithAverages,
    tmpOrderDraftItemDetails: {
      productId: '',
      internalInfo: '',
      additionalInfo: ''
    } as ItmpOrderDraftItemDetails,
    valueChangedIndicator: false,
    isPriceLoaded: false,

    // Modal/Dialog
    modalOrderDraftItemDetails: false,
    modalCloseOrderDraftItemDetails: false,

    // Validation rules
    amountKgRules: [
      (v: string) => /^\s*\d+[.,]\d{1,3}\s*$/.test(v) || /^\s*\d+\s*$/.test(v) || 'Wrong syntax: only decimals with two decimal places allowed',
      (v: string) => {
        if (!isNaN(parseFloat(v)) && parseFloat(v) !== 0) {
          return /^[1-9]/.test(v) || /0\.\d*/.test(v) || 'Amount must be bigger than 0.';
        }
        return true;
      }
    ] as validationRuleType[],
    amountStkRules: [
      (v: string) => /^\s*\d+\s*$/.test(v) || 'Wrong syntax: only integers allowed',
      (v: string) => {
        if (Number(v) > 0) {
          return /^[1-9]/.test(v) || 'Amount must be bigger than 0.';
        }
        return true;
      }
    ] as validationRuleType[]
  }),
  computed: {
    ...mapState('contact', ['currentContact']),
    ...mapGetters('order', ['getOrderDraft']),

    getSelectedProduct(): ProductWithAverages {
      return this.selectedProduct;
    },
    getValueChangedIndicator(): boolean {
      return this.valueChangedIndicator;
    },
    calcAmountRules(): validationRuleType[] {
      switch (this.inputProduct.sell_unit) {
        case UnitsEnums.STK:
          return this.amountStkRules;
        case UnitsEnums.KG:
          return this.amountKgRules;
        default:
          return this.amountKgRules;
      }
    }
  },
  methods: {
    ...mapActions('order', ['setOrderDraftItemDetails', 'setOrderDraftItemPrice']),
    async addDetailsToOrderDraftItem(product: ProductWithAverages): Promise<void> {
      this.selectedProduct = product;
      this.$emit('setSelectedProduct', product);
      const selectedOrderDraftItem = OrderDraftService.findOrderDraftItemByProductId(this.getOrderDraft, product.product_id) as IOrderDraftItem;
      this.$emit('setSelectedOrderDraftItem', selectedOrderDraftItem);

      this.tmpOrderDraftItemDetails.productId = product.product_id;
      if (selectedOrderDraftItem.internalInfo) {
        this.tmpOrderDraftItemDetails.internalInfo = selectedOrderDraftItem.internalInfo;
      }
      if (selectedOrderDraftItem.additionalInfo) {
        this.tmpOrderDraftItemDetails.additionalInfo = selectedOrderDraftItem.additionalInfo;
      }

      this.modalOrderDraftItemDetails = true;
    },
    async clickOrderDraftItemInputField(product: ProductWithAverages): Promise<void> {
      this.$emit('setSelectedProduct', product);
      const selectedOrderDraftItem = OrderDraftService.findOrderDraftItemByProductId(this.getOrderDraft, product.product_id) as IOrderDraftItem;
      this.$emit('setSelectedOrderDraftItem', selectedOrderDraftItem);
      this.$emit('setIsOrderDraftItemsCountValid', OrderDraftService.isAtLeastOneOrderDraftItemToOrder(this.getOrderDraft));
    },
    async saveSelectedOrderDraftItemBtn(): Promise<void> {
      await this.setOrderDraftItemDetails(this.tmpOrderDraftItemDetails);
      this.modalOrderDraftItemDetails = false;
      this.valueChangedIndicator = false;
      this.tmpOrderDraftItemDetails = this.resetTmpOrderDraftItemDetails();
    },
    toggleCalculationForModalCloseOrderDraftItemDetails(): void {
      if (this.getValueChangedIndicator) {
        this.modalCloseOrderDraftItemDetails = true;
      } else {
        this.modalOrderDraftItemDetails = false;
        this.valueChangedIndicator = false;
      }
    },
    closeOrderDraftItemDetailsWithoutSaving(): void {
      this.modalOrderDraftItemDetails = false;
      this.modalCloseOrderDraftItemDetails = false;
      this.valueChangedIndicator = false;
      this.tmpOrderDraftItemDetails = this.resetTmpOrderDraftItemDetails();
    },
    resetTmpOrderDraftItemDetails(): ItmpOrderDraftItemDetails {
      return {
        productId: '',
        internalInfo: '',
        additionalInfo: ''
      };
    },
    castAmountFromNumberToString(value: number, sellUnit: string): string {
      return NumberUtils.castAmountFromNumberToString(value, sellUnit);
    },
    castAmountFromStringToDecimalNumber(value: string, sellUnit: string): number {
      return NumberUtils.castAmountFromStringToDecimalNumber(value, sellUnit);
    },
    onInputAmountField(orderItemIndex: number, productSellUnit: string, value: string): void {
      this.getOrderDraft.orderItems[orderItemIndex].rawAmount = value;
      this.getOrderDraft.orderItems[orderItemIndex].amount = NumberUtils.castAmountFromStringToDecimalNumber(value, productSellUnit);
    },
    onChangeAmountField(orderItemIndex: number, productSellUnit: string): void {
      this.getOrderDraft.orderItems[orderItemIndex].formattedAmount = NumberUtils.castAmountFromNumberToString(
        this.getOrderDraft.orderItems[orderItemIndex].amount,
        productSellUnit
      );
      // TODO: do not check global validation (isAtLeastOneOrderDraftItemToOrder), return here info only for this item?
      // in this case we can not bind validation with different input field (price) from the same OrderDraftItem.
      this.$emit('setIsOrderDraftItemsCountValid', OrderDraftService.isAtLeastOneOrderDraftItemToOrder(this.getOrderDraft));
    }
  },
  watch: {
    tmpOrderDraftItemDetails: {
      handler() {
        this.$emit('setTmpOrderDraftItemDetails', this.tmpOrderDraftItemDetails);
      },
      immediate: true,
      deep: true
    }
  },
  i18n: {
    messages: {
      en: {
        orderDraftItemDetails_dialogTitle: 'Order item details',
        closeOrderDraftItemDetails_dialogTitle: 'You really want to close this window?',
        closeOrderDraftItemDetails_dialogText: "All you changes won't be saved. Please confirm your decision."
      },
      de: {
        orderDraftItemDetails_dialogTitle: 'Auftragspositionsdetails',
        closeOrderDraftItemDetails_dialogTitle: 'Wollen Sie dieses Fenster wirklich schließen?',
        closeOrderDraftItemDetails_dialogText: 'Alle Ihre Änderungen werden nicht gespeichert. Bitte bestätigen Sie Ihre Entscheidung.'
      }
    }
  }
});
