




































import Vue from 'vue';
import { mapState, mapGetters } from 'vuex';
import convertDate from '@/utils/ConvertDate';
import ProductProperty from '@/components/Common/ProductCard/ProductProperty.vue';
import RepositoryFactory from '@/services/api/RepositoryFactory';
import { ProductsRepository } from '@/services/api/IEntityRepository';
import { IOrderDraft } from '@/store/modules/order/types';
import { ProductPrice } from '@/components/Common/ProductCard/types';
import moment from 'moment/moment';

const repositoryFactory = new RepositoryFactory();
const productRepository: ProductsRepository = repositoryFactory.get('products') as ProductsRepository;

export default Vue.extend({
  name: 'Product.vue',
  components: {
    ProductProperty
  },
  data: () => ({
    productPrice: {} as ProductPrice,
    isProductPriceLoaded: false
  }),
  props: ['product', 'showCart'],
  computed: {
    ...mapState('contact', ['products', 'currentContact']),
    ...mapState('global', ['current_view']),
    ...mapGetters('order', ['getOrderDraft']),
    productDetails() {
      const { quantity, weight, total_value: totalValue } = this.product;
      return [
        {
          icon: 'mdi-sigma',
          value: quantity,
          unit: 'pcs'
        },
        {
          icon: 'mdi-sigma',
          value: weight,
          unit: 'kg'
        },
        {
          icon: 'mdi-sigma',
          value: totalValue,
          unit: '€'
        }
      ];
    },
    productAverage() {
      const { priceByQuantity, priceByWeight } = this.product;
      const { total_value: totalValue, quantity, weight } = this.product;
      // eslint-disable-next-line vue/no-async-in-computed-properties
      const TOTAL = +totalValue;
      const QUANITY = +quantity;
      const WEIGHT = +weight;
      const quanityPrice = QUANITY ? TOTAL / QUANITY : 0;
      const weightPrice = WEIGHT ? TOTAL / WEIGHT : 0;

      const optionalPriceByQuantity = Math.round((quanityPrice + Number.EPSILON) * 100) / 100;
      const optionalPriceByWeight = Math.round((weightPrice + Number.EPSILON) * 100) / 100;
      return [
        {
          icon: 'ø',
          value: priceByQuantity || optionalPriceByQuantity,
          unit: '€ / pcs'
        },
        {
          icon: 'ø',
          value: priceByWeight || optionalPriceByWeight,
          unit: '€ / kg'
        }
      ];
    },
    lastAmount(): string {
      return this.product.last_amount;
    },
    lastPrice(): string {
      return this.product.last_price;
    },
    lastPriceDate(): string {
      return convertDate(this.product.last_price_date);
    }
  },
  methods: {
    redirectToCreateOrder() {
      const { id } = this.$route.params;
      const { product_id: productId } = this.$props.product;
      this.$router.push({
        name: 'createOrder',
        query: {
          account_id: id,
          product_id: productId,
          customer_id: this.products.customer_id,
          from: this.current_view
        }
      });
    },
    async getProductPrice(): Promise<void> {
      const orderDraft: IOrderDraft = this.getOrderDraft;

      const newDate = new Date(Date.now() + 24 * 60 * 60 * 1000);

      const deliveryDate = orderDraft ? orderDraft.deliveryDate : moment(newDate).format('YYYY-MM-DD');

      const { data } = await productRepository.getProductPrice(
        `/products/${this.product.product_id}/price`,
        deliveryDate,
        this.currentContact.id
      );
      this.productPrice = {
        icon: '€',
        value: data.price.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }),
        unit: ''
      };
      this.isProductPriceLoaded = true;
    }
  },
  mounted() {
    (this as any).getProductPrice();
  }
});
